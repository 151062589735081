import HealthDashboardIcon from 'Assets/icons/HealthDashboardIcon.svg';
import FlowIcon from 'Assets/icons/FlowIcon.svg';
import ToolingCoverageIcon from 'Assets/icons/ToolingCoverageIcon.svg';
import PoliciesIntegrationIcon from 'Assets/icons/PoliciesIntegrationIcon.svg';
import OktaSignInIcon from 'Assets/icons/Okta.svg';
import { BsGoogle } from '@react-icons/all-files/bs/BsGoogle';
import { BsFillShieldLockFill } from '@react-icons/all-files/bs/BsFillShieldLockFill';
import { ReactSVG } from 'react-svg';
import {
    ACTIONS_WORKFLOWS_ROUTE,
    ACTIVE_WORKFLOWS_ROUTE,
    DEFAULT_FINDINGS_ROUTE,
    DEFAULT_WORKFLOW_ROUTE,
    DEPT_DASHBOARD_ROUTE,
    HOME_ROUTE,
    ORG_DASHBOARD_ROUTE,
    POLICIES_ROUTE,
    SETTINGS_SPECIFIC_ROUTE,
    TOOLING_COVERAGE_ROUTE,
    USERS_DASHBOARD_ROUTE,
} from '../routes';
import IdentityTools from 'Assets/icons/IdentityTools.svg';
import EndPointTools from 'Assets/icons/EndPointTools.svg';
import VulnerabilityTools from 'Assets/icons/VulnerabilityTools.svg';
import WebGateWayTools from 'Assets/icons/WebGateWayTools.svg';
import TrainingTools from 'Assets/icons/TrainingTools.svg';
import AssetMgmtTools from 'Assets/icons/assetMgmtTools.svg';
import MessagingTools from 'Assets/icons/messagingTools.svg';
import { Color } from 'Core-utils/types/color';
import { MdSettings } from '@react-icons/all-files/md/MdSettings';
import { ActionItemType, MenuItem } from 'Core-utils/types/types';
import { Category, FindingSeverity, FindingStatus } from '@ampsec/platform-client';
import { FiExternalLink } from '@react-icons/all-files/fi/FiExternalLink';
import { CredentialsScreenData } from 'Components/organisms/CredentialsScreenModal/CredentialsScreen';
import Block from 'Assets/icons/block.svg';
import { TenantAmpSetting } from 'Components/pages/SettingsPage/SettingsPage';

export const tabs = [
    {
        eventKey: 'org',
        title: 'Organization',
        link: ORG_DASHBOARD_ROUTE,
    },
    {
        eventKey: 'dept',
        title: 'Departments',
        link: DEPT_DASHBOARD_ROUTE,
    },
    { eventKey: 'users', title: 'Users', link: USERS_DASHBOARD_ROUTE },
];

export const ENGAGEMENT_FLOW_TABS = [
    {
        eventKey: 'active-flows',
        title: 'ENGAGEMENTS',
        link: ACTIVE_WORKFLOWS_ROUTE,
    },
    {
        eventKey: 'actions',
        title: 'ACTIONS',
        link: ACTIONS_WORKFLOWS_ROUTE,
    },
];
export const MFA_TOTAL_USERS_LABEL = 'health_mfa_total_users_label';
export const MFA_TOTAL_ACTIVE_LABEL = 'health_mfa_total_active_label';
export const SECURITY_HEALTH_LABEL = 'health_score_label';
export const TOOLING_COVERAGE_GAPS = 'TOOLING COVERAGE GAPS';
export const VULNERABILITIES = 'OUT OF SLA VULNERABILITIES ';
export const CRITICAL_LABEL = 'health_critical_vulnerability_label';
export const HIGH_LABEL = 'health_high_vulnerability_label';
export const MEDIUM_LABEL = 'health_medium_vulnerability_label';
export const LOW_LABEL = 'health_low_vulnerability_label';
export const FISHING_LABEL = 'health_training_failed_phishing_label';
export const OVERDUE_TRAINING_LABEL = 'health_training_missed_training';
export const MTTT_LABEL = 'health_training_mttt_days_label';
export const AVG_FREQ_LABEL = 'health_training_frequency_days_label';
export const TOTAL_EMPLOYEE_CTXLABEL = 'trend_increased_by';
export const ZSCALER_CTXLABEL = 'report_tooling_coverage_label';
export const TOTAL_ENDPOINT_CTXLABEL = 'trend_increased_by';
export const CROWDSTRIKE_CTXLABEL = 'report_tooling_coverage_label';
export const TENABLE_CTXLABEL = 'report_tooling_coverage_label';
export const TOOLING_COVERAGE_LABEL_COLORS: Color[] = [
    'text-high-emphasis',
    'secondary-accent-orange-2',
    'text-high-emphasis',
    'secondary-accent-yellow-1',
    'secondary-accent-red-1',
];

export const TOP_RISK_CONTRIBUTORS_MFA_LABEL = 'health_contributor_mfa_label';
export const TOP_RISK_CONTRIBUTORS_VULN_LABEL = 'health_contributor_vulnerability_label';
export const TOP_RISK_CONTRIBUTORS_TRAINING_LABEL = 'health_contributor_training_label';
export const TOP_RISK_CONTRIBUTORS_TOOLING_LABEL = 'health_contributor_tooling_label';
export const viewerNavItems = [
    {
        Icon: HealthDashboardIcon,
        text: 'Health Dashboard',
        link: HOME_ROUTE,
    },
    {
        Icon: ToolingCoverageIcon,
        text: 'TOOLING COVERAGE',
        link: TOOLING_COVERAGE_ROUTE,
    },
    {
        Icon: <BsFillShieldLockFill style={{ width: '1.5rem', height: '1.5rem' }} />,
        text: 'Security Findings',
        link: DEFAULT_FINDINGS_ROUTE,
    },
];
export const adminNavItems = [
    {
        Icon: HealthDashboardIcon,
        text: 'Health Dashboard',
        link: HOME_ROUTE,
    },
    {
        Icon: ToolingCoverageIcon,
        text: 'TOOLING COVERAGE',
        link: TOOLING_COVERAGE_ROUTE,
    },
    {
        Icon: <BsFillShieldLockFill style={{ width: '1.5rem', height: '1.5rem' }} />,
        text: 'Security Findings',
        link: DEFAULT_FINDINGS_ROUTE,
    },
    {
        Icon: FlowIcon,
        text: 'Engagement Studio',
        link: DEFAULT_WORKFLOW_ROUTE,
    },
    {
        Icon: PoliciesIntegrationIcon,
        text: 'Integrations & Policies',
        link: POLICIES_ROUTE,
    },
    {
        Icon: <MdSettings size={28} />,
        text: 'Settings',
        link: SETTINGS_SPECIFIC_ROUTE,
    },
];

export const TEMP_USER_EMAIL = 'sam.maxwell@amplifiersecurity.com';

export const SEVERITY_TEXT = 'Severity';
export const DATE_TEXT = 'Date';
export const ISSUE_TYPE_TEXT = 'Issue Type';
export const NAME = 'Name';
export const ENGAGEMENT = 'Engagement';
export const SECURITY_CONTEXT_TEXT = 'Security Training Deficiencies';
export const HEALTH_TRENDS_TEXT = 'HEALTH TREND';
export const FAILED_PHISHING_TEXT = 'FAILED PHISHING';
export const MFA_COVERAGE_TITLE = 'MFA COVERAGE';
export const MFA_COVERAGE_SUB_TITLE = 'total employees';
export const SECURITY_HEALTH = 'SECURITY HEALTH';
export const SECURITY_HEALTH_SUBTITLE = ' self-healed findings';
export const SECURITY_TRAINING_DEFICIENCIES = 'SECURITY TRAINING DEFICIENCIES';

export const VULNERABILITIES_LABELS = [CRITICAL_LABEL, HIGH_LABEL, MEDIUM_LABEL, LOW_LABEL];
export const TRAINING_DEFICIENCIES_LABELS = [FISHING_LABEL, OVERDUE_TRAINING_LABEL];

export const ENGAGEMENT_VALUE_LABELS = ['TOTAL FINDINGS', 'CLOSED FINDINGS', 'FINDING ENGAGED', 'AMPY INTERACTIONS'];

export const TOTAL_FINDINGS_LABEL = 'total_findings_label';
export const TOTAL_FINDINGS_CLOSED_LIFETIME_LABEL = 'total_findings_closed_lifetime_label';
export const TOTAL_ENGAGEMENTS_LABEL = 'total_engagements_label';
export const TOTAL_NOTIFICATIONS_LABEL = 'total_notifications_label';

export const EngagementValuesLabelMap: Record<string, string> = {
    [ENGAGEMENT_VALUE_LABELS[0]]: TOTAL_FINDINGS_LABEL,
    [ENGAGEMENT_VALUE_LABELS[1]]: TOTAL_FINDINGS_CLOSED_LIFETIME_LABEL,
    [ENGAGEMENT_VALUE_LABELS[2]]: TOTAL_ENGAGEMENTS_LABEL,
    [ENGAGEMENT_VALUE_LABELS[3]]: TOTAL_NOTIFICATIONS_LABEL,
};

export const TOTAL_EMPLOYEES = 'TOTAL EMPLOYEES';
export const TOTAL_ENDPOINTS = 'TOTAL ENDPOINTS';
export const VulnerabilitiesLabelMap: Record<string, string> = {
    [CRITICAL_LABEL]: 'CRITICAL',
    [HIGH_LABEL]: 'HIGH',
    [MEDIUM_LABEL]: 'MEDIUM',
    [LOW_LABEL]: 'LOW',
};

export const TOTAL_EMPLOYEE_LABEL = 'report_tooling_total_employees_label';
export const TOTAL_ENDPOINT_LABEL = 'report_tooling_total_endpoints_label';
export const JAMF_LABEL = 'provider_jamf_name';
export const TOP_RISK_CONTRIBUTORS_TITLE = 'TOP RISK CONTRIBUTORS';
export const ToolingCoverageCtxLabelMap: Record<string, string> = {
    [TOTAL_EMPLOYEE_CTXLABEL]: 'increased by',
    [ZSCALER_CTXLABEL]: 'Not logged in',
    [JAMF_LABEL]: 'Not logged in',
};

export const TrainingDeficiencyLabelMap: Record<string, string> = {
    [FISHING_LABEL]: 'FAILED PHISHING',
    [OVERDUE_TRAINING_LABEL]: 'OVERDUE TRAININGS',
};

export const PHISHING = 'FAILED PHISHING';
export const TRAINING = 'OVERDUE TRAININGS';

export const ERROR_MSG = ['Error loading details. ', 'Refresh'];
export const EMPTY_DATA_MSG = 'No risks have been identified.';
export const LOADING_MSG = 'LOADING...';
export const TOP_RISK_CONTRIBUTORS = 'TOP RISK CONTRIBUTORS';
export const RADAR_LABELS = ['MFA Coverage', 'SLA Vulnerabilities', 'Training', 'Tooling Coverage'];
export const EMPTY_LABELS = ['', '', '', ''];
export const TOOLTIP_CONTENT =
    'This represents the security health score of the organization, highlighting specific risk zones';

export const SAVE = 'Save As Default Filter';
export const RESET = 'Reset Default Filter';
export const SignInButtonsMaps = [
    {
        Icon: <BsGoogle size={21} />,
        text: 'Sign in with Google',
        id: 'Google',
    },
    {
        Icon: <ReactSVG src={OktaSignInIcon} />,
        text: 'Sign in with Okta',
        id: 'Okta',
    },
];

export const SignInInfoText = `Self-healing technology that will engage & empower employees to amplify an organization's security health.`;
export const NOT_COVERAGE_TEXT = 'Not protected';

export const USER_TABLE_ROWS_PER_PAGE_OPTIONS = [20, 30, 40, 50];
export const USERS_TABLE_FIXED_HEIGHT = '77vh';
export const SEARCHBAR = 'Search';
export const USERHEADER = ['Designation', 'Email ID', 'Organization', 'Tenure', 'Department', 'Manager'];
export const rowsPerPageOptions = [20, 30, 40, 50];
export const USER_SECURITY_HEALTH = 'USER SECURITY HEALTH';
export const ORGANIZATION_SCORE = 'ORG SCORE';
export const DEPARTMENT_SCORE = 'DEPT SCORE';
export const DropdownItems = [
    { id: 0, value: 'EVERYONE' },
    { id: 1, value: 'EMPLOYEES ONLY' },
    { id: 2, value: '3RD PARTIES' },
];
export const EMPTY_TABLE_MESSAGE = `No data available.`;
export const FINDINGS_TABLE_PAGINATION = [50, 100];
export const HEADER_SCROLL_HEIGHT = '304px';
export const BACK = 'BACK';
export const DUPLICATE = 'DUPLICATE';
export const HISTORY = 'HISTORY';
export const SAVE_START = 'SAVE & START';
export const EngagemntFlowData = [{ label: DUPLICATE }, { label: HISTORY }, { label: SAVE_START }];

export const DEFAULT_TABLE_BODY_HEIGHT = '832px';

export const SIGN_IN_ERROR_TEXT = 'Sign in authentication failed';
export const TRY_AGAIN = 'Try Again';
export const DASHBOARD_SIGN_IN = 'DASHBOARD SIGN IN';
export const GET_IN_TOUCH = 'GET IN TOUCH';

export const USER_SCORE_THRESHOLD_VALUES = {
    CRITICAL: 70,
    HIGH: 80,
    MEDIUM: 90,
};
export const FLOW_HEADER_TEXTS = [
    'Name',
    'Target Users',
    'Last Triggered',
    'Active engagements',
    'All time engagements',
    'Status',
    'Closed engagements',
    'Healing rate',
    'Avg healing time',
];
export const FLOW_TABLE_HEIGHT = '496px';

export const CLEAR_TEXT = 'Clear';
export const APPLY_TEXT = 'Apply';
export const COMINGSOON = 'Amplifier is in Alpha';
export const SUBTEXT = ['We’re working hard behind the scenes to make every', 'employee a part of your security team'];

export const ENGAGEMENTVALUE = 'PRODUCT VALUE';
export const ENGAGEMENTDATA = [
    { label: 'TOTAL FINDINGS', value: '1.4k' },
    { label: 'TOTAL ENGAGEMENTS', value: '34k' },
    { label: 'TIME SAVED', value: '40 hrs' },
    { label: 'DOLLARS SAVED', value: '$239k' },
];
export const DEFAULT_TREND_MONTHS = 6;

const TRAINING_LABEL = 'TRAINING';
const WEB_GATEWAY_LABEL = 'WEB_GATEWAY';
const IDENTITY_LABEL = 'IDENTITY';
const VULNERABILITY_LABEL = 'VULNERABILITY';
const EDR_LABEL = 'EDR';
const CUSTOM_LABEL = 'CUSTOM';
export const MFA_LABEL = 'MFA';

export const TOP_RISK_CONTRIBUTORS_MAP: Record<string, string> = {
    [TRAINING_LABEL]: 'TRAIN',
    [WEB_GATEWAY_LABEL]: 'WEB',
    [IDENTITY_LABEL]: 'IDEN',
    [MFA_LABEL]: 'MFA',
    [EDR_LABEL]: 'EDR',
    [VULNERABILITY_LABEL]: 'VULN',
    [CUSTOM_LABEL]: 'CUST',
};

export const GOOGLE_SIGNIN = 'Google SSO';
export const INTEGRATED = 'Integrated';
export const GOOGLE_INTEGRATION_TABS = [
    {
        eventKey: 'overview',
        title: 'OVERVIEW',
    },
    {
        eventKey: 'installation guide',
        title: 'INSTALLATION GUIDE',
    },
];

export const ADD_INTEGRATION = 'Add Integration';

export const OVERVIEW_TAB_CONTENT =
    'Simplify and secure user logins to Amplifier from a trusted provider. Validate that users are using Google supported MFA and engage with them to remediate issues.';
export const PROVIDER_INTEGRATION_TABS = ['OVERVIEW', 'INSTALLATION GUIDE'];

export const INSTALLATION_GUIDE_CONTENT = [
    {
        header: '',
        content:
            'Simplify and secure user logins to Amplifier from a trusted provider. Validate that users are using Google supported MFA and engage with them to remediate issues.',
    },
    {
        header: 'PREREQUISITES',
        content:
            'Simplify and secure user logins to Amplifier from a trusted provider. Validate that users are using Google supported MFA and engage with them to remediate issues.',
    },

    {
        header: 'INSTRUCTIONS',
        content:
            'Simplify and secure user logins to Amplifier from a trusted provider. Validate that users are using Google supported MFA and engage with them to remediate issues.',
    },
];
//TODO:[EZ-215] links are to be added
export const SIDEBAR = [
    {
        title: 'Get Support',
        link: 'mailto:support@amplifiersecurity.com?subject=Amplifier%20Support',
    },
    {
        title: 'Terms of Use',
        link: 'https://www.amplifiersecurity.com/tos',
    },
    {
        title: 'Privacy Policy',
        link: 'https://www.amplifiersecurity.com/privacy',
    },
];
export const INTEGRATEDTOOLHEADERS = ['No Tools Integrated', 'Add Integration', 'Configure'];
export const TOASTMESSAGE = [
    'HELPING YOU INTEGRATE MORE SECURITY TOOLS AT AMPLIFIER.',
    'Let’s get started with integrating your first security tool!',
];
export const INTEGRATIONSTITLE = 'INTEGRATIONS';
export const INTEGRATIONSDATA = [
    { id: '1', imageUrl: IdentityTools, domain: 'Identity & Access' },
    { id: '2', imageUrl: EndPointTools, domain: 'Endpoint Security' },
    { id: '3', imageUrl: VulnerabilityTools, domain: 'Vulnerability Management' },
    { id: '4', imageUrl: WebGateWayTools, domain: 'Web Gateway' },
    { id: '5', imageUrl: TrainingTools, domain: 'Security Training' },
    { id: '6', imageUrl: AssetMgmtTools, domain: 'Vulnerability Mangement' },
    { id: '7', imageUrl: MessagingTools, domain: 'Collaboration' },
    { id: '5', imageUrl: TrainingTools, domain: 'Security Compliance' },
];
export const GOOGLE_SIGN_IN = 'Google SSO';
export const NOTFOUNDPAGE = [
    'INTEGRATION',
    '404',
    '- Page not found',
    'The link you followed probably broken or the page has been removed.',
];

export const ENGAGE_WITH_FLOW_CONTENT =
    'You will be proceeded to the engagement studio. Are you sure you want to engage with flow?';

export const ENGAGE = 'Engage';
export const ADD = 'ADD';
export const GROUP_NAME = 'Group Name';
export const NUMBER_OF_EMPLOYEES = 'Number Of Employees';
export const DESCRIPTION = 'Description';
export const IntegrationsDropdownItems = [
    { id: 0, value: 'User Groups' },
    { id: 1, value: 'Users' },
];
export const ADDED_DATE = 'Added Date';
export const SOURCE_TYPE = 'Source Type';
export const ADD_GROUPS = 'Add Groups';
export const ADD_USERS = 'Add Users';
export const EMAIL_ID = 'Email Id';
export const DEPARTMENT = 'Department';
export const POSITION = 'Position';
export const UserGroupData = [
    {
        id: 1,
        name: 'Developers',
        addedDate: '2022-03-14T06:28:48Z',
        description: 'This group consists of all the developers integrated with Okta',
        sourceType: 'Okta',
        close: '',
    },
    {
        id: 2,
        name: 'Designers',
        addedDate: '2022-03-14T06:28:48Z',
        description: 'This group consists of all the designers integrated with Okta',
        sourceType: 'Okta',
        close: '',
    },
    {
        id: 3,
        name: 'Project Managers',
        addedDate: '2022-03-14T06:28:48Z',
        description: 'This group consists of all the pm integrated with Okta',
        sourceType: 'Okta',
        close: '',
    },
    {
        id: 4,
        name: 'Finance',
        addedDate: '2022-03-14T06:28:48Z',
        description: 'This group consists of all the finance integrated with Okta',
        sourceType: 'Okta',
        close: '',
    },
    {
        id: 5,
        name: 'IT Team',
        addedDate: '2022-03-14T06:28:48Z',
        description: 'This group consists of all the IT team integrated with Okta',
        sourceType: 'Okta',
        close: '',
    },
];

export const AllUserGroupsData = [
    {
        id: 1,
        groupName: 'Developers',
        numberOfEmployees: 45,
        description: 'This group consists of all the developers integrated with Okta',
    },
    {
        id: 2,
        groupName: 'Designers',
        numberOfEmployees: 100,
        description: 'This group consists of all the designers integrated with Okta',
    },
    {
        id: 3,
        groupName: 'Project Managers',
        numberOfEmployees: 45,
        description: 'This group consists of all the pm integrated with Okta',
    },
    {
        id: 4,
        groupName: 'Finance',
        numberOfEmployees: 25,
        description: 'This group consists of all the finance integrated with Okta',
    },
    {
        id: 5,
        groupName: 'Management',
        numberOfEmployees: 25,
        description: 'This group consists of all the marketing integrated with Okta',
    },
    {
        id: 6,
        groupName: 'Accounts',
        numberOfEmployees: 25,
        description: 'This group consists of all the accounts integrated with Okta',
    },
    {
        id: 7,
        groupName: 'Managers',
        numberOfEmployees: 25,
        description: 'This group consists of all the managers integrated with Okta',
    },
    {
        id: 8,
        groupName: 'Interns',
        numberOfEmployees: 25,
        description: 'This group consists of all the interns integrated with Okta',
    },
    {
        id: 9,
        groupName: 'Engineers',
        numberOfEmployees: 25,
        description: 'This group consists of all the engineers integrated with Okta',
    },
    {
        id: 10,
        groupName: 'IT Team',
        numberOfEmployees: 25,
        description: 'This group consists of all the IT team integrated with Okta',
    },
];
export const ENGAGE_HEADER = 'ENGAGE WITH EMPLOYEES';
export const MEDIUM = 'Medium';
export const SEND = 'Send';
export const COPY_SECURITY_TEAM = 'Copy Security Team';
export const INCLUDE_FINDINGS_DATA = 'Include Finding Data';
export const INTEGRATIONHEADER = ['Status : ', 'Last time updated : ', ' ago', 'Updates every', 'seconds'];
export const INTEGRATIONSTATUSLABEL: Record<string, string> = {
    sync: 'Not Configured',
    disabled: 'Disabled',
    error: 'Error',
    active: 'Active',
};
export const CANCEL = 'Cancel';
export const CREATE = 'Create';
export const CONFIRM = 'Confirm';
export const INVITE_USER = [
    { id: '1', value: 'jakobchrishop@amplifier.com', displayValue: 'jakobchrishop@amplifier.com', disable: false },
    {
        id: '2',
        value: 'savannah@amplifier.com',
        displayValue: 'savannah@amplifier.com',
        disable: false,
    },
    { id: '3', value: 'russell@amplifier.com', displayValue: 'russell@amplifier.com', disable: false },
    { id: '4', value: 'estherhoward@amplifier.com', displayValue: 'estherhoward@amplifier.com', disable: false },
    { id: '5', value: 'henry0@amplifier.com', displayValue: 'henry0@amplifier.com', disable: false },
];
export const CONFIGURED = 'Configured';
export const NOT_CONFIGURED = 'Not Configured';
export const WARNING =
    'WARNING : Deleting this integration will stop syncing vulnerability data from tenable to the Amplifier platform.';
export const REMOVE = 'Remove';
export const REMOVE_INTEGRATION = 'REMOVE INTEGRATION';
export const COMPLIANCE_LABEL = 'engagement_compliance_label';
export const TOTAL_HEALED_LABEL = 'engagement_healed_label';
export const ACTIVE_ENGAGEMENTS_LABEL = 'engagement_active_label';
export const ENGAGEMENT_DASHBOARD_MAP: Record<string, string> = {
    [TOTAL_HEALED_LABEL]: 'TOTAL FINDINGS',
    [ACTIVE_ENGAGEMENTS_LABEL]: 'SELF HEALED',
    [COMPLIANCE_LABEL]: 'SELF-HEALING RATE',
};
export const VULNERABILITIES_HEALED = 'VULNERABILITIES HEALED';
export const TRAINING_DEFICIENCIES_HEALED = 'TRAINING DEFICIENCIES HEALED';
export const TOOLING_GAPS_HEALED = 'TOOLING GAPS HEALED';
export const SELF_HEALING_STATS = 'SELF HEALING STATS';
export const LAST_HEALED = 'Last Healed';
export const DOMAIN = 'Domain';
export const ISSUE_NAME = 'Issue Name';
export const NUMBER_OF_ENGAGEMENTS = '# Of Engagements';
export const SELF_HEALED_ENGAGEMENTS = 'SELF HEALED ENGAGEMENTS';
export const REFRESH = 'Refresh';
export const LIVE = 'Live';
export const COMPANY_PARAMETERS = 'COMPANY PARAMETERS';
export const SHARE_SECURITY_POSTURE = 'Share Security Posture';
export const SETTINGS = 'SETTINGS';
export const ORGANIZATION = 'Organization';
export const ACCESS = 'Access';
export const DATA_GRID_HEIGHT = '18.75rem';
export const INVITE = 'INVITE';
export const EMAIL = 'Email';
export const EMAIL_ENGAGEMENT_CHANNEL = 'Email (Non-interactive)';
export const SAVE_TEXT = 'save';
export const CANCEL_TEXT = 'cancel';
export const MFA_NOT_ENABLED = 'mfa_not_enabled';
export const MFA_NOT_SECURE = 'mfa_not_secure';
export const IS_EXECUTIVE = 'is_executive';
export const HAS_PRODUCTION_ACCESS = 'has_production_access';
export const HAS_PRIVILEGED_ACCESS = 'has_privileged_access';
export const MFA_NO_ACCOUNT = 'mfa_no_account';
export const HEALTH_TRAINING_FAILED_PHISHING = 'health_training_failed_phishing';
export const HEALTH_TRAINING_MISSED_TRAINING = 'health_training_missed_training';
export const HEALTH_TRAINING_NO_ACCOUNT = 'health_training_no_account';
export const WEB_GATEWAY_NOT_LOGGED_IN = 'web_gateway_not_logged_in';
export const WEB_GATEWAY_NO_ACCOUNT = 'web_gateway_no_account';
export const DEVICE_NOT_MANAGED = 'device_not_managed';
export const VULNERABILITY_OUT_OF_SLA = 'vulnerability_out_of_sla';
export const HIGH_VULNERABILITY_OUT_OF_SLA = 'high_vulnerability_out_of_sla';
export const MEDIUM_VULNERABILITY_OUT_OF_SLA = 'medium_vulnerability_out_of_sla';
export const LOW_VULNERABILITY_OUT_OF_SLA = 'low_vulnerability_out_of_sla';
export const CRITICAL_VULNERABILITY_OUT_OF_SLA = 'critical_vulnerability_out_of_sla';
export const VULNERABILITY_NO_ACCOUNT = 'vulnerability_no_account';
export const DEVICE_MANAGEMENT_NO_ACCOUNT = 'device_management_no_account';
export const DEVICE_CHECKIN_OVERDUE = 'device_checkin_overdue';
export const DEVICE_NOT_ENCRYPTED = 'device_not_encrypted';
export const ENDPOINT_PROTECTION_MISSING = 'endpoint_protection_missing';
export const FAILED_PHISHING = 'failed_phishing';
export const FIREWALL_DISABLED = 'firewall_disabled';
export const SCREENSAVER_DISABLED = 'screensaver_disabled';
export const SOFTWARE_UPDATE_AVAILABLE = 'software_update_available';
export const TRAINING_OVERDUE = 'training_overdue';
export const UNQUARANTINED_MALWARE_DETECTED = 'unquarantined_malware_detected';
export const SECURITY_FINDINGS = 'SECURITY FINDINGS';
export const TOOLING_COVERAGE_PAGE = 'TOOLING COVERAGE';
export const VULNERABILITY_NAME = 'Vulnerability Name';
export const CREATED_AT = 'Created at';
export const PROVIDER = 'Provider';
export const GOOGLE = 'Google';
export const STATUS = 'Status';
export const FINDING = 'Finding';
export const ENTER_CREDENTIALS = 'ENTER CREDENTIALS';
export const INTEGRATE_NOW = 'Integrate Now';
export const ALL_ORGS: MenuItem = { id: 'ALL', value: 'ALL' };
export const TENANT_SELECTION = [
    'SELECT YOUR ORGANIZATION',
    'Select from the list of available organizations below to proceed',
];

export const departmentFilterMap: Record<string, keyof typeof filterMap> = {
    TOOLING: 'Tooling',
    MFA: 'MFA',
    TRAINING: 'Training',
    'OUT OF SLA': 'Vulnerabilities',
};

export const securityFindingsColumnMap: Record<string, string> = {
    findingCategory: 'finding_category',
    findingKind: 'finding_kind',
    userDepartment: 'user_department',
    userOrganization: 'user_organization',
};

export const filterMap = {
    Tooling: {
        [securityFindingsColumnMap.findingCategory]: ['WEB_GATEWAY', 'EDR'],
        [securityFindingsColumnMap.findingKind]: ['DEVICE_NOT_MANAGED', 'WEB_GATEWAY_NOT_ACTIVE', 'NO_ACCOUNT'],
    },
    Vulnerabilities: {
        [securityFindingsColumnMap.findingKind]: [
            'CRITICAL_VULNERABILITY_OUT_OF_SLA',
            'HIGH_VULNERABILITY_OUT_OF_SLA',
            'MEDIUM_VULNERABILITY_OUT_OF_SLA',
            'LOW_VULNERABILITY_OUT_OF_SLA',
        ],
    },
    MFA: {
        [securityFindingsColumnMap.findingCategory]: ['IDENTITY'],
        [securityFindingsColumnMap.findingKind]: ['MFA_NOT_ENABLED'],
    },
    Training: {
        [securityFindingsColumnMap.findingCategory]: ['TRAINING'],
        [securityFindingsColumnMap.findingKind]: ['FAILED_PHISHING', 'TRAINING_OVERDUE'],
    },
};
export const GHOST_MODE = 'Ghost Mode';
export const GHOST_MODE_SUB_TEXT =
    'User engagement notifications for employees will be discontinued, with security team notifications remaining active.';
export const MODAL_TITLE_MAP = {
    GHOST_MODE: GHOST_MODE,
};
export const CONFIRMATION_BUTTON_LABEL = 'YES, ENABLE IT';
export const CLEAR_ALL_FILTERS = 'Clear All Filters';
export const optionsFields = ['Severity', 'Provider', 'Issue Type'];
export const columnFilterMap: Record<string, string> = {
    provider: 'provider.displayValue',
};

export const AMP_ENUMS: { [key: string]: string } = {
    ORGANIZATION: 'organization',
    DEPARTMENT: 'department',
    TITLE: 'position',
    SEVERITY: 'severity',
    [columnFilterMap.provider]: 'provider',
    CATEGORY: 'issue type',
};
export const GOOGLE_SIGN_IN_ERROR = 'Google Sign in authentication failed.';
export const TERMS_OF_USE = 'Terms of use';
export const PRIVACY_POLICY = 'Privacy policies';
export const TERMS_OF_USE_LINK = 'https://www.amplifiersecurity.com/tos';
export const PRIVACY_POLICY_LINK = 'https://www.amplifiersecurity.com/privacy';
export const PAUSE_NOTIFICATION = 'PAUSE NOTIFICATION';
export const MODAL_CONTENT_MAP: Partial<Record<TenantAmpSetting, Record<string, string>>> = {
    NOTIFICATIONS_ENABLED: {
        true: 'Are you sure you want to disable notifications?',
        false: 'Are you sure you want to enable notifications?',
    },
    DEFAULT_NOTIFICATION_CONNECTOR_ID: {
        true: 'Are you sure you want to save this?',
    },
    '': {},
};
export const BUTTON_LABEL_MAP: Partial<Record<TenantAmpSetting, Record<string, string>>> = {
    NOTIFICATIONS_ENABLED: {
        true: 'YES, DISABLE IT',
        false: 'YES, ENABLE IT',
    },
    '': {},
};
export const BETWEEN_PREPOSITION = 'of';
export const PAUSE_NOTIFICATION_CONTENT =
    'Are you sure you want to pause notifications for these users? They will not receive any notifications during the selected time frame';
export const SELECTED = 'Selected';
export const DURATIONS = [
    {
        id: 1,
        value: '1 Week',
    },
    {
        id: 2,
        value: '1 Month',
    },
    {
        id: 3,
        value: 'Forever',
    },
];
export const MODIFY_STATUS = 'MODIFY STATUS';
export const MODIFY_STATUS_PRIMARY_BUTTON_TEXT = 'YES, MODIFY IT';
export const MODIFY_STATUS_CONTENT = 'Are you sure you want to modify the status of these findings?';
export const ACCEPT_FINDINGS = 'ACCEPT FINDINGS';
export const ACCEPT_FINDINGS_CONTENT = 'Are you sure you want to accept these findings?';
export const ACCEPT_FINDINGS_PRIMARY_BUTTON_TEXT = 'YES, ACCEPT IT';
export const FORM_SUBMIT_BUTTON_LABEL = 'Submit';
export const DURATION = 'Duration';
export const ENGAGE_SUCCESS_MSG = 'Message sent to the employees successfully.';
export const ACCEPT_FINDINGS_SUCCESS_MSG = 'You have accepted the selected findings.';
export const FALSE_POSITIVE_SUCCESS_MSG = 'Selected findings marked as false positive.';
export const OPEN_SUCCESS_MSG = 'You have re-opened the selected findings';
export const DEFAULT_ERROR_MSG = 'An unexpected error has occurred. Please contact the support team.';
export const ALL = 'ALL';

export const FINDINGS_STATUS = [
    FindingStatus.ACCEPTED_RISK,
    FindingStatus.FALSE_POSITIVE,
    FindingStatus.PENDING_VERIFICATION,
];
export const FindingStatusLabelMap: Record<string, string> = {
    [FINDINGS_STATUS[0]]: 'ACCEPTED',
    [FINDINGS_STATUS[1]]: 'F/P',
    [FINDINGS_STATUS[2]]: 'PENDING',
};
export const ENGAGE_VARIANT = 'engage';
export const ACCEPT_FINDINGS_VARIANT = 'accepted';
export const FALSE_POSITIVE_VARIANT = 'f/p';
export const OPEN_VARIANT = 'open';
export const ERROR_VARIANT = 'error';
export const SOLO = 'SOLO';
export const EMPLOYEE = 'EMPLOYEE';
export const TOOLING = 'TOOLING';
export const MFA = 'MFA';
export const TRAINING_TEXT = 'TRAINING';
export const OUT_OF_SLA = 'OUT OF SLA';
export const ACCEPT_FINDINGS_LABEL = 'Accept Findings';
export const FALSE_POSITIVE_LABEL = 'False Positive';
export const DEVELOPMENT = 'development';
export const SUCCESS_VARIANT = 'success';
export const INFO_VARIANT = 'info';
export const WARNING_VARIANT = 'warning';
export const DEPARTMENTS = 'DEPARTMENTS';
export const EMPTY_MESSAGE = `Oops! It seems like there is no data\n${'\u00A0'.repeat(7)}available at the moment`;
export const NO_RESULTS_FOUND = 'No results found';
export const SAVED_QUESTIONS = 'Saved Questions';
export const VIEW_QUERY = 'View Query';
export const FINDINGS_PROMPT_HEADER = 'Ask your Questions';
export const PROMPT_PREFIX = 'TRY';
export const VIEW_QUERY_BUTTON = 'View Query';
export const FINDINGS_PROMPT_INPUT_PLACEHOLDER = 'Type...';
export const DEFAULT_TABS = ['overviewLong', 'overviewRichText', 'installationInstructions'];
export const ALLOWED_TABS = ['overviewLong', 'overviewRichText'];
export const ERROR_MESSAGE = 'There was an unknown error. Please try again later';
export const CREDENTIALS_SAVED = 'Credentials saved';
export const CONNECTOR_SETTINGS_SAVED = 'Connector settings saved';
export const OAUTH_SETTINGS_SAVED = 'OAuth settings saved';
export const CONNECTOR_CREATED = 'Connector created -';
export const NO_DATA_STATE_HEIGHT = '55vh';
export const LOADER_DATA_STATE_HEIGHT = '720px';
export const FIXED_HEADER_SCROLL_HEIGHT = '770px';
export const FINDINGS_GRID_MODAL_HEIGHT = '685px';
export const FINDINGS_GRID_DEFAULT_HEIGHT = '480px';
export const SAVED_QUERIES_EMPTY_MESSAGE = `Oops! It seems like there are no saved\n${'\u00A0'.repeat(
    7,
)}questions at the moment`;
export const REMOVE_INTEGRATION_CONTENT = 'Are you sure you want to remove this integration?';
export const YES_DELETE_IT = 'YES, DELETE IT';
export const ENABLE = 'ENABLE';
export const DISABLE = 'DISABLE';
export const REMOVE_TOAST_MESSAGE = 'Connector removed successfully';
export const SUPPORT = 'Support';
export const SUPPORT_TEXT = 'This integration is \nsupported and maintained \nby Amplifier  ';
export const REGENERATE = 'Regenerate';
export const ENGAGEMENT_SAVE = 'SAVE';
export const ENGAGEMENT_EDIT = 'EDIT';
export const USER_COHORT = 'USER COHORT';
export const ADDCOHORT = 'Add Cohort';
export const USERCOHORTSUBHEADER = 'Everyone else will be ';
export const USER_COHORT_CAPTION = 'Add User Cohort';
export const ADD_COHORT = 'Add Cohort';
export const USER_COHORT_SUBHEADER = 'Everyone else will be ';
export const INTEGRATION = 'Integration';
export const FINDING_TYPE = 'Finding Type';
export const ADD_FINDINGS = 'Add Findings';
export const FINDINGS = 'FINDINGS';
export enum AIServiceModelName {
    GPT3 = 'gpt-3.5-turbo-16k',
    GPT4 = 'gpt-4',
    TEXT_BISON = 'text-bison-32k',
    CODE_BISON = 'code-bison-32k',
}
export const CREATE_ENGAGEMENT = 'CREATE ENGAGEMENT';
export const PREVIEW_ENGAGEMENT = 'Preview Engagement';
export const BUILD_ENGAGEMENT = 'Back to builder';
export const ENGAGEMENT_TITLE = 'Engagement Title';
export const ENGAGEMENT_DESCRIPTION = 'Engagement Description';
export const ENGAGEMENT_TITLE_VALIDATION_TEXT = 'Engagement title cannot be empty.';
export const ADD_CHANNEL = 'Add Channel';
export const ENGAGEMENT_CHANNEL = 'ENGAGEMENT CHANNEL';
export const ADD_ENGAGEMENT_CHANNEL = 'Add Engagement Channel';
export const AND = 'and';
export const MORE = 'more';
export const SEARCH_PLACEHOLDER = 'Search by name';
export const PROMPT_CONFIGURATIONS = 'PROMPT CONFIGURATIONS';
export const SIDE_PANEL_SUB_TEXT = 'Assemble your engagement by dragging features into the canvas.';
export const DRAG_PROMPT_TEXT = 'Drag a Prompt here to add it to your engagement';
export const VIEW_IN_SECURITY_FINDINGS = 'VIEW IN SECURITY FINDINGS';
export const USER_COHORT_ERROR_MESSAGE = 'Field should not be empty';
export const VIEW_QUERY_MODAL_TITLE = 'VALIDATE YOUR QUERY';
export const PROMPT_HELPER_TEXT = 'Was that prompt helpful?';
export const LinkItems = [
    {
        id: '1',
        Icon: <FiExternalLink className="text-text-high-emphasis" />,
        text: 'FAQ',
        link: '',
    },
    {
        id: '2',
        Icon: <FiExternalLink className="text-text-high-emphasis" />,
        text: 'Help',
        link: '',
    },
];
export const RESET_LABEL = 'RESET';
export const CONFIGURE_MORE_ACTIONS = 'Configure more actions';
export const REWARDS_HEADING = 'Rewards';
export const BIOMETRIC = 'Biometric';
export const ENDPOINTS = 'Endpoints';
export const NUMBER_OF_ENDPOINTS = 'Number Of Endpoints';
export const IDENTITY_AND_ACCESS = 'Identity & Access';
export const SECURITY_TRAINING = 'Security Training';
export const ENDPOINT_MANAGEMENT = 'Endpoint Management';
export const ENDPOINT_SECURITY = 'Endpoint Security';
export const VULNERABILITY_MANAGEMENT = 'Vulnerability Management';
export const HEALTH_SCORE_CONFIGURATION = 'HEALTH SCORE CONFIGURATION';
export const HEALTH_SCORE_CONFIGURATION_TABS = ['Health Score Configuration', 'Configure High Risk Cohort'];
export const CONFIGURE_FINDINGS_SCORE_LABELS = ['Findings', 'Deductions'];
export const CONFIGURATION_HIGH_RISK_COHORT_LABELS = ['User Cohort', 'Factor'];
export const POINTS = 'pts';
export const HEALTH_SCORE_CONFIGURATION_PLACEHOLDER = ['Select Finding', 'Select User Cohort'];
export const CUSTOM_FINDINGS = 'Custom Findings';
export const WEBHOOK = 'WEBHOOK';
export const INSTRUCTIONS = 'Instructions';
export const WEBHOOK_CREDENTIALS = 'Webhook Credentials';
export const FINDINGS_CONFIGURATION = 'Findings Configuration';
export const WEBHOOK_CONFIG_DATA: CredentialsScreenData[] = [
    {
        label: 'URL',
        value: 'http://www.YourDomain.com',
        placeholder: 'URL',
        variant: 'default',
    },
];
export const FINDINGS_ITEMS = [
    {
        name: 'severity',
        options: Object.values(FindingSeverity).map((value, index) => ({
            id: index + 1,
            value: value as string,
        })),
    },
    {
        name: 'issueType',
        options: Object.values(Category).map((value, index) => ({
            id: index + 1,
            value: value as string,
        })),
    },
];
export const FINDING_NAME = 'Finding Name';
export const FINDING_DISPLAY_NAME = 'Finding (Display Name)';
export const ADD_FINDING = 'ADD FINDING';
export const IMPORT_FROM_PAYLOAD = 'Import from payload';
export const CUSTOM_INSTRUCTIONS =
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo conDuis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatu';
export const EVENT_TYPE = 'Event Type';
export const FINDING_NAME_CANNOT_BE_EMPTY = 'Finding Name cannot be empty';
export const EMPTY_TYPE_CANNOT_BE_EMPTY = 'Event Type cannot be empty';
export const DESCRIPTION_CANNOT_BE_EMPTY = 'Description cannot be empty';
export const ISSUE_TYPE_CANNOT_BE_EMPTY = 'Issue Type cannot be empty';
export const FINDING_CANNOT_BE_EMPTY = 'Finding cannot be empty';
export const ADD_PROPERTY_PATH = 'Add Property Path';
export const ROWS_PER_PAGE = 'Rows per page :';
export const ERROR = 'ERROR';
export const ALL_ACTIONS = 'ALL ACTIONS';
export const ACTION_NAME = 'Action Name';
export const ACTION_TARGET = 'Action Target';
export const ACTION_TRIGGERED = 'Triggered in last 30 days';
export const FAILED_ACTIONS = 'Failed Runs';
export const ADD_NEW_ACTION = 'ADD NEW ACTION';
export const CONFIGURE_ACTION = 'CONFIGURE ACTION';
export const ADD_HEADER = 'ADD HEADER';
export const ADD_PARAMETER = 'ADD PARAMETER';
export const ACTION_TABLE_HEADERS = ['KEY', 'VALUE'];
export const ACTION_LABELS = [
    'Authorization',
    'Payload Format',
    'Action Name',
    'URL',
    'Method',
    'Description',
    'Headers',
    'Parameters',
    'Payload Value',
];
export const CustomActionsLabelMap: Record<string, string> = {
    [ACTION_LABELS[1]]: 'CUSTOM_ACTION_PAYLOAD_FORMAT_HELP',
    [ACTION_LABELS[2]]: 'CUSTOM_ACTION_ACTION_NAME_HELP',
    [ACTION_LABELS[3]]: 'CUSTOM_ACTION_URL_HELP',
    [ACTION_LABELS[4]]: 'CUSTOM_ACTION_METHOD_HELP',
    [ACTION_LABELS[5]]: 'CUSTOM_ACTION_DESCRIPTION_HELP',
    [ACTION_LABELS[6]]: 'CUSTOM_ACTION_HEADERS_HELP',
    [ACTION_LABELS[7]]: 'CUSTOM_ACTION_PARAMETERS_HELP',
};
export const STEPPER_BUTTON_LABELS = ['Previous', 'Next', 'Save'];
export const CUSTOM_ACTION = 'Custom Action';
export const CUSTOM_ACTION_HEADERS = ['General Information', 'Request Configuration', 'Advanced Settings'];
export const SUPPORTING_TEXT = ' should not be empty.';
export const TOOLING_COVERAGE_ROWS_PER_PAGE_OPTIONS = [20, 30, 40, 50];
export const AMPLIFIER = 'Amplifier';
export const API_CALL = 'API Call';
export const CUSTOM_ACTION_LABELS = [
    'payload',
    'NONE',
    'url',
    'textAreaValue',
    'displayValue',
    'description',
    'method',
    'headers',
    'parameters',
    '',
];
export const INACTIVE = 'Inactive';
export const USER_MANAGEMENT = 'USER MANAGEMENT';
export const LAST_LOGGED_IN = 'Last Logged In';
export const DISABLE_NOTIFICATIONS = 'Disable Notifications/Engagements';
export const DISABLE_NOTIFICATIONS_CAPTION =
    'All notifications and engagements will be disabled for employees and security admin.';
export const NOTIFICATIONS_DISABLED = 'Notifications Disabled';
export const NOTIFICATIONS_DISABLED_CAPTION = [
    'All notifications are currently disabled. Change',
    'settings',
    'to enable it.',
];
export const FAILED_RUN = 'FAILED RUNS';
export const DELETE_ENGAGEMENT = 'DELETE ENGAGEMENT';
export const ENGAGEMENT_MODAL_CONTENT = 'Are you sure you want to remove this engagement?';
export const DELETE_ACTION = 'DELETE ACTION';
export const ACTION_MODAL_CONTENT = 'Are you sure you want to remove this action?';
export const DELETE_FINDING = 'DELETE FINDING';
export const FINDING_MODAL_CONTENT = 'Are you sure you want to remove this finding?';
export const ENTER_VALID_EMAIL = 'Enter a valid email address';
export const USER_RBAC_OPTIONS = [
    {
        id: '1',
        event: 'ACTIVE',
        value: 'ACTIVATE USER',
    },
    {
        id: '2',
        event: 'REMOVE',
        value: 'REMOVE USER',
    },
    {
        id: '3',
        event: 'SUSPENDED',
        value: 'SUSPEND USER',
    },
];

export const ACCESS_OPTIONS = [
    {
        id: '1',
        value: 'Admin',
    },
    {
        id: '2',
        value: 'Viewer',
    },
    {
        id: '3',
        value: 'Owner',
    },
];
export const DELETE_CUSTOM_SCORE_VALUE = 'DELETE CUSTOM SCORE VALUE';
export const CUSTOM_SCORE_VALUE_MODAL_CONTENT = 'Are you sure you want to remove this custom score value?';
export const INVALID_URL_TEXT = ' should Start with http:// or https:// ';
export const ENTER_VALID_JSON = 'is not in valid JSON format.';
export const OUTSTANDING_VULNERABILITIES = 'OUTSTANDING VULNERABILITIES';
export const BY_SEVERITY = 'By severity';
export const TOTAL_TEXT = 'Total';
export const TOOLING_COVERAGE_COMPLIANCE = 'TOOLING COVERAGE (% COMPLIANCE BY ENDPOINTS)';
export const TOOLING_COVERAGE_MTTR = 'TOOLING MTTR ANALYSIS';
export const VULNERABILITY_MTTR = 'VULNERABILITY MTTR ANALYSIS';
export const TRAINING_COMPLIANCE = ['TRAINING COMPLIANCE (%)', 'TRAINING COMPLIANCE (NUMBERS)'];
export const COMPLETED_TRAINING = 'Completed training (%)';
export const OVERDUE_TRAINING = 'Overdue training (nos.)';
export const PRODUCT_VALUE_DASHBOARD = 'PRODUCT VALUE DASHBOARD';
export const TRAINING_MTTR_ANALYSIS = 'TRAINING MTTR ANALYSIS';
export const IN_THIS_QUARTER = 'in this quarter';
export const DELETE_CUSTOM_SCORE_COHORT = 'DELETE CUSTOM SCORE COHORT';
export const CUSTOM_SCORE_VALUE_MODAL_COHORT = 'Are you sure you want to remove this custom score cohort?';
export const NEVER = 'never';
export const DO_NOTHING_ID = 'lQJ6KK2vpN';
export const DO_NOTHING_TEXT = 'DO NOTHING';
export const DONT_CHANGE = 'DONT CHANGE';
export function generateAllItems(actions: ActionItemType[]) {
    const actionItems = actions?.map((item) => ({
        id: item.id,
        icon: item.serviceKey?.logo,
        value: item.displayValue,
    }));

    const doNothingItem: { id: string; icon: string; value: string | null; displayValue: string } = {
        id: DO_NOTHING_ID,
        icon: Block,
        value: null,
        displayValue: DO_NOTHING_TEXT,
    };

    const allItems = [...(actionItems || []), doNothingItem];

    return allItems;
}
export function generateAllStatus() {
    const status = Object.values(FindingStatus).map((value, index) => ({
        id: index + 1,
        displayValue: value as string,
        value: value as string | null,
    }));

    const allStatus = status.concat({
        id: status.length + 1,
        displayValue: DONT_CHANGE,
        value: null,
    });

    return allStatus;
}
export const DEDUCTION_SCORE_ERROR = 'Input number should be in the range of 0 to 99';
export const ERROR_SCORE_TEXT = 'The score should be between 0 - 99';
export const fieldNameMap: Record<string, string | string[]> = {
    ['Schedule Resolution']: 'actionId',
    ['Triage (Yes/No)']: ['yesActionId', 'noActionId'],
    ['Message tone']: 'nature',
    ['Resolution Deadline']: ['deadlineTime', 'deadlineUnit'],
    ['Reminder']: ['reminderTime', 'reminderUnit'],
    ['Delay Engagement']: ['delayEngagementTime', 'delayEngagementUnit'],
};

export const ACTIVE_ENGAGEMENTS_MODAL_HEADER = 'ACTIVE ENGAGEMENTS';
export const ACTIVE_ENGAGEMENTS_MODAL_COLUMNS = {
    userName: 'User Name',
    scheduledDate: 'Scheduled Date',
    noOfReminders: 'No of Reminders',
    openFor: 'Open For',
    expirationIn: 'Expiration In',
};
export const ACTIVE_ENGAGEMENT_CHIP_TOOLTIP = {
    SCHEDULED_DATE: 'Scheduled date',
    NO_OF_REMINDERS: 'No. of reminders',
    EXPIRATION_DATE: 'Expiration date',
    OPEN_FOR: 'Open for',
    STATUS: 'Status',
};
